import $ from 'jquery';
import { _x } from '@wordpress/i18n';

let fieldsAreInitialized = false;

function changeFileUpload(formID) {
  // select right file uploads
  let singleFileUploads;
  if (formID) {
    const form = document.querySelector('form[data-formid="' + formID + '"');
    singleFileUploads = form.querySelectorAll('.gfield--fileupload-single');
  } else {
    singleFileUploads = document.querySelectorAll('.gfield--fileupload-single');
  }
  // single file uploads
  if (0 < singleFileUploads.length) {
    singleFileUploads.forEach(function (fileUpload) {
      const input = fileUpload.querySelector('input[type="file"]');
      const label = fileUpload.querySelector('.gfield_label');
      const noFileLabel = _x('No file selected', 'file upload', 'vtx');

      // create elements
      const wrapper = document.createElement('div');
      const removeBtn = document.createElement('button');
      const uploadBtn = document.createElement('p');
      const uploadBtnIcon = document.createElement('span');
      const uploadBtnLabel = document.createElement('span');
      const filePlaceholder = document.createElement('p');

      // add classes
      wrapper.classList.add('fake-upload');
      filePlaceholder.classList.add('fake-upload__file-placeholder');
      uploadBtn.classList.add('fake-upload__upload-btn');
      uploadBtnLabel.classList.add('fake-upload__upload-btn__label');
      uploadBtnIcon.classList.add('fake-upload__upload-btn__icon');
      uploadBtnIcon.classList.add('icon-download');
      removeBtn.classList.add('fake-upload__remove-btn');

      // add text contents
      removeBtn.innerHTML =
        '<span class="sr-only">' +
        _x('Clear file', 'file upload', 'vtx') +
        '</span><span class="icon-close"></span>';
      uploadBtnLabel.textContent = _x('Select a file', 'file upload', 'vtx');
      filePlaceholder.textContent = input.value ? input.value : noFileLabel;

      // extra attributes
      removeBtn.setAttribute('type', 'button');

      // append elements
      uploadBtn.appendChild(uploadBtnIcon);
      uploadBtn.appendChild(uploadBtnLabel);
      wrapper.appendChild(uploadBtn);
      wrapper.appendChild(filePlaceholder);
      wrapper.appendChild(removeBtn);
      label.after(wrapper);

      input.addEventListener('change', function () {
        if (input.value) {
          const filename = input.value.replace(/^C:\\fakepath\\/i, '');
          filePlaceholder.textContent = filename;
          removeBtn.classList.add('visible');
        } else {
          filePlaceholder.textContent = noFileLabel;
          removeBtn.classList.remove('visible');
        }
      });

      input.addEventListener('focus', function () {
        wrapper.classList.add('has-focus');
      });

      input.addEventListener('blur', function () {
        wrapper.classList.remove('has-focus');
      });

      removeBtn.addEventListener('click', function () {
        input.value = '';
        filePlaceholder.textContent = noFileLabel;
        removeBtn.classList.remove('visible');
      });
    });
  }
}

$(document).on('gform_post_render', function (e, formID) {
  fieldsAreInitialized = true;
  changeFileUpload(formID);
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
  }
});
