import domReady from '@wordpress/dom-ready';

domReady(() => {
  const wrapper = document.querySelector('#modal-form-wrapper');
  const toggles = document.querySelectorAll('.modal-form-toggle');

  if (wrapper && 0 < toggles.length) {
    toggles.forEach((toggle) => {
      toggle.addEventListener('click', (event) => {
        event.stopPropagation();
        if (document.body.classList.contains('modal-form-open')) {
          document.body.classList.remove('modal-form-open');
          toggles.forEach((btn) => {
            const srLabel = btn.querySelector('.screen-reader-label');
            srLabel.textContent = srLabel.getAttribute('data-label-open');
            btn.setAttribute('aria-expanded', 'false');
          });
        } else {
          document.body.classList.add('modal-form-open');
          toggles.forEach((btn) => {
            const srLabel = btn.querySelector('.screen-reader-label');
            srLabel.textContent = srLabel.getAttribute('data-label-close');
            btn.setAttribute('aria-expanded', 'true');
          });
        }
      });
    });
  }
});
